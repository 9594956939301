<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="名称" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="参数" align="center">
				  <template slot-scope="scope">
					<span v-for="(item,index) in scope.row.values">{{item.title || ''}},必填:{{item.required == 1 ? '是' : '否'}};</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="110">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="问题名称" prop="name">
			  <el-input v-model="form.name" placeholder="请输入问题名称" type="text" clearable />
			</el-form-item>
			<el-form-item label="问题等级" prop="title">
			  <el-input v-model="form.title" placeholder="请输入问题等级(多个|隔开)" type="text" clearable />
			</el-form-item>
			<el-form-item label="参数值">
				<div class="w-100 flex flex-column">
					<div class="w-100 flex align-center mb-1" v-for="(item,index) in params" :key="index"  v-dragging="{ item: item, list: params, group: 'item' }">
						<el-select v-model="item.index" placeholder="请选择参数" style="width: 250px;margin-right: 10px;" @change="paramsChange($event,index)">
						  <el-option :value="key" :label="val.title" v-for="(val,key) in template_params" :key="key"/>
						</el-select>
						<el-select v-model="item.required" placeholder="是否必填" style="width: 250px;margin-right: 10px;">
						  <el-option value="1" label="是"/>
						  <el-option value="2" label="否"/>
						</el-select>
						<el-select v-model="item.group" multiple placeholder="请选择组别" style="width: 250px;margin-right: 10px;" @change="groupChange($event,index)">
						  <el-option value="" label="不选"/>
						  <el-option :value="item.name" :label="item.label" v-for="(item,index) in group" :key="item.id"/>
						</el-select>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" @click="delSpec(index)">
								<i class="el-icon-minus"></i>
							</el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="拖拽" placement="top">
							<el-button type="danger">
								<i class="el-icon-sort"></i>
							</el-button>
						</el-tooltip>
					</div>
					<div class="w-100 flex align-center">
						<el-button type="danger" @click="addParams">
							<i class="el-icon-plus"></i>添加
						</el-button>
					</div>
				</div>
			</el-form-item>
		   <el-form-item  label="状态">
			  <el-radio-group v-model="form.status">
				<el-radio :label="1">使用</el-radio>
				<el-radio :label="2">禁用</el-radio>
			  </el-radio-group>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
			<el-button size="mini" @click="cancel">取 消</el-button>
			<el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'template',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					name:'',
					title:'',
					values:[
						{index:'',id:'',title:'',required:2,group:[]}
					],
					status:1,
				},
				rules:{
					name: [
					  { required: true, message: '请输入模板名称', trigger: 'blur' }
					],
					values: [
					  { required: true, message: '请选择模板值', trigger: ['blur', 'change'] }
					],
				},
				params:[],
				template_params:[],
				group:[
					{id:1,name:'A',label:'A-语文、数学、英语、物理、化学、生物'},
					{id:2,name:'B',label:'B-语文、数学、英语、物理、化学、地理'},
					{id:3,name:'C',label:'C-语文、数学	、英语、物理	、化学、政治'},
					{id:4,name:'D',label:'D-语文、数学	、英语、物理、生物、地理'},
					{id:5,name:'E',label:'E-语文、数学、英语、物理、生物、政治'},
					{id:6,name:'F',label:'F-语文、数学、英语、物理、地理、政治'},
					{id:7,name:'G',label:'G-语文、数学、英语、历史、化学、生物'},
					{id:8,name:'H',label:'H-语文、数学、英语、历史、化学、地理'},
					{id:9,name:'I',label:'I-语文、数学、英语、历史、化学、政治'},
					{id:10,name:'J',label:'J-语文、数学、英语、历史、生物、地理'},
					{id:11,name:'K',label:'K-语文、数学、英语、历史、生物、政治'},
					{id:12,name:'L',label:'L-语文、数学、英语、历史、地理、政治'},
					{id:13,name:'H',label:'H-语文、数学、英语、历史、化学、地理'},
					{id:14,name:'N',label:'N-语文、数学、英语、物理、化学、生物、政治、历史、地理'},
				],
			}
		},
		mounted() {
			this.getTemplateParams()
		},
		methods:{
			handleEdit(row) {
			  this.reset()
			  this.open = true
			  this.title = '编辑'
			  this.form = JSON.parse(JSON.stringify(row))
			  this.params = row.values
			},
			delSpec(index){
				this.params.splice(index,1)
				this.$forceUpdate()
			},
			groupChange(e,index){
				if(e){
					this.params[index].group = e
				}else{
					this.params[index].group = ''
				}
				this.$forceUpdate()
			},
			paramsChange(e,index){
				this.params[index].index = e
				this.params[index].id = this.template_params[e].id
				this.params[index].title = this.template_params[e].title
				this.params[index].group = []
			},
			addParams(){
				this.params.push({index:'',id:'',title:'',required:'2',group:[]})
			},
			getTemplateParams(){
				this.axios.get('/manage/template_params/all').then(res=>{
					if(res.status){
						this.template_params = res.data
					}
				})
			},
			reset() {
			  this.form = {
				name:'',
				title:'',
				values:[
					{index:'',id:'',title:'',required:2,group:[]}
				],
				status:1,
			  }
			  this.params = []
			  this.resetForm('form')
			},
			handleSubmit() {
			  this.$refs.form.validate(valid => {
				if (valid) {
				  if (this.form.sort) {
					this.form.sort = parseInt(this.form.sort)
				  }
				  this.form.values = this.params
				  if (this.form.id === undefined) {
					this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
					  if (res.status) {
						this.$message.success('添加成功')
						this.open = false
						this.getList()
					  } else {
						this.$message.error(res.msg)
					  }
					})
				  } else {
					this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
					  if (res.status) {
						this.$message.success('修改成功')
						this.open = false
						this.getList()
					  } else {
						this.$message.error(res.msg)
					  }
					})
				  }
				}
			  })
			},
		}
	}
</script>

<style>
</style>